//nav style specifically for postid-204 - Empowerment page

.postid-204 {
  .nav-pre-top {
    [class*="anniversary"] {
      .nav__menu__link {
        &:before {
          height: 76px;
          width: 134px;
          background: transparent
          url("/wp-content/themes/riseboro/build/images/bug-50plus-anniversary-white-blue.png")
          center center no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

// general nav styles here
nav {
  ul,
  li {
    list-style: none;
  }
}

.nav__branding {
  max-width: 100%;
  max-height: 3em;
  height: auto;
}

.nav__section {
  margin-bottom: 2em;
  strong {
    @include typeHeaderNav;
  }
}

.nav__menu {
  margin: 0;
  padding: 0;
}

.nav {
  .nav__menu__link {
    @include typeNavLinkTop;
    display: inline-block;
    padding-bottom: 0.6em;
  }
}

.nav-top,
.nav-pre-top {
  .nav__menu {
    display: flex;
    align-content: flex-end;
    padding: 0 $gutter;
  }
  .nav__menu__link {
    margin-left: 2.75em;
    @include typeNavLinkTopContrast;
    i {
      font-size: 0.7em;
      margin-right: 0.5em;
      line-height: 0.7em;
      font-weight: bold;
      position: relative;
      top: -0.25em;
    }
  }
}

.nav-top {
  .nav__menu {
    margin-top: 0.5em;
  }
  @include media-breakpoint-down(xs) {
    display: block;
    .nav__menu,
    .nav__home {
      width: 100%;
      max-width: none;
    }
    .nav__menu {
      margin-top: 2em;
      padding: 0;
    }
    .nav__menu__item {
      @include make-col-ready();
      flex: auto;
      width: auto;
      .nav__menu__link {
        text-align: center;
        margin: 0;
        display: block;
      }
      &:first-child {
        .nav__menu__link {
          margin-left: 0;
          text-align: left;
        }
      }
      &:last-child {
        .nav__menu__link {
          text-align: right;
        }
      }
    }
  }
}

.nav-pre-top {
  .nav__menu {
    border-bottom: 1px solid $light;
    margin-bottom: em(16);
    padding: 0;
    align-items: center;
  }
  .nav__menu__item {
    border-right: 1px solid $light;
    margin-right: 1em;
    margin-bottom: 1em;
    &:last-child {
      margin-right: 0;
      border: none;
      .nav__menu__link {
        margin-right: 0;
      }
    }
  }
  .nav__menu__link {
    margin-right: 1em;
    margin-left: 0;
    margin-bottom: 0;
    padding: 0;
    &:after {
      content: "\279C";
      display: inline-block;
      margin-left: 0.5em;
      color: $blueLight;
    }
  }
  [class*="hiring"],
  [class*="careers"] {
    .nav__menu__link {
      font-weight: bold;
      text-transform: uppercase;
      border: 2px solid $light;
      border-radius: 2em;
      padding: 0.5em 1.5em 0.25em;
      font-size: 1.2em;
      &:hover {
        color: $bluePrimary;
        text-decoration: none;
        background: $light;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1em;
      }
    }
  }
  [class*="anniversary"],
  [class*="gala"] {
    .nav__menu__link {
      font-size: 0;
      display: flex;
      align-items: center;
      margin-top: 2px;
      margin-right: 16px;
      &:hover {
        text-decoration: none;
      }
      &:before {
        content: "";
        height: 38px;
        width: 67px;
        background: transparent
          url("/wp-content/themes/riseboro/build/images/bug-gala.png") center
          center no-repeat;
        background-size: contain;
        display: inline-block;
      }
      &:after {
        font-size: 19px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  [class*="anniversary"] {
    .nav__menu__link {
      &:before {
        height: 76px;
        width: 134px;
        background: transparent
          url("/wp-content/themes/riseboro/build/images/bug-50plus-anniversary-gold.png")
          center center no-repeat;
        background-size: contain;
      }
    }
  }
  [class*="gala_2024"] {
    .nav__menu__link {
      &:before {
        height: 86px;
        width: 140px;
        border: solid 7px white;
        background: transparent
          url("/wp-content/themes/riseboro/build/images/bug-gala-2024.png")
          center center no-repeat;
        background-size: contain;
        background-color: white;
      }
    }
  }
  [class*="gala_2023"] {
    .nav__menu__link {
      &:before {
        height: 76px;
        width: 250px;
        background: transparent
          url("/wp-content/themes/riseboro/build/images/bug-50th-anniversary-gala.png")
          center center no-repeat;
        background-size: contain;
      }
    }
  }
}

.nav-site {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  .nav__section {
    &:first-child,
    &:nth-child(2) {
      grid-column-start: 1;
    }
    &:first-child {
      grid-row-start: 1;
    }
    &:nth-child(2) {
      grid-row-start: 2;
    }
    &:nth-child(3) {
      grid-column-start: 2;
      grid-row: 1 / span 2;
    }
    .nav__menu {
      columns: 2;
      @include media-breakpoint-down(xs) {
        columns: 1;
      }
    }
  }
  .nav__section-services {
    .nav__menu__item:first-child {
      .nav__menu__link {
        font-weight: bold;
      }
    }
  }
  .nav__menu__item-division {
    @extend .bug-division;
    .nav__menu__link {
      border-bottom: 0.2em solid transparent;
    }
    @each $name, $div in $divisions {
      &.nav__menu__item-#{$name} {
        .nav__menu__link {
          color: map-get($div, color);
          padding: 0;
          margin-bottom: 0.6em;
          &:hover {
            border-bottom-color: map-get($div, color);
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    margin-top: 2em;
    .nav__section {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        padding-right: $gutter;
      }
      &:nth-child(2) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
      }
      &:nth-child(3) {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;
    .nav__section {
      &:nth-child(2) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
      }
      &:nth-child(3) {
        grid-row-start: 3;
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }
  }
}

.nav-social {
  .nav__menu__link {
    font-weight: bold;
  }
}
